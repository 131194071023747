var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 pt-3 mb-2 d-flex align-start",class:[
    _vm.$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end',
    _vm.$vuetify.breakpoint.xsOnly && 'mx-auto'
  ]},[_c('div',{staticClass:"version-selector"},[_c('v-select',{attrs:{"data-public":"","value":_vm.selectedVersion,"items":_vm.versions,"dense":"","label":"Version","hide-details":""},on:{"update:value":function($event){_vm.selectedVersion=$event},"change":_vm._changeVersion}})],1),_c('v-tooltip',{attrs:{"bottom":"","max-width":"340","open-delay":!_vm.isQuickRunnable || _vm.archived
        ? 0
        : 750},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"vertical-button mr-2 position-relative",staticStyle:{"height":"46px"},attrs:{"color":"primary","text":"","tile":"","small":"","data-cy":"start-flow-quick-run","disabled":!_vm.isQuickRunnable || _vm.archived,"loading":_vm.isRunning},on:{"click":_vm.quickRunFlow}},[_c('v-icon',[_vm._v("fa-rocket")]),_c('v-icon',{staticClass:"position-absolute",style:({
              bottom: '-4px',
              right: '8px',
              transform: 'rotate(15deg)'
            }),attrs:{"small":""}},[_vm._v(" offline_bolt ")]),_c('div',{staticClass:"mb-1"},[_vm._v("Quick Run")])],1)],1)]}}])},[(!_vm.isQuickRunnable)?_c('span',[_vm._v(" This flow has required parameters that must be set before a run. Set a default parameter value in the flow settings page or select the RUN tab to launch this flow. ")]):(_vm.archived)?_c('span',[_vm._v(" This flow is archived and cannot be run. ")]):_c('span',[_c('p',[_vm._v(" Run this flow immediately, with a generated flow run name and default parameters. ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Select the RUN tab if you want to customize the flow run name, set specific parameters, or provide context. ")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-badge',{attrs:{"value":_vm.schedule == null && _vm.isScheduled,"color":"warning","icon":"priority_high","overlap":"","top":"","left":""}},[_c('div',{staticClass:"vertical-button"},[_c('v-switch',{staticClass:"mr-1 v-input--vertical",attrs:{"data-cy":"schedule-toggle","hide-details":"","color":"primary","loading":_vm.scheduleLoading,"disabled":_vm.disableToggle || _vm.archived},on:{"change":_vm.scheduleFlow},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-btn',{staticClass:"mb-1",attrs:{"small":"","text":"","disabled":"","tile":""}},[_vm._v(" Schedule ")])]},proxy:true}],null,true),model:{value:(_vm.isScheduled),callback:function ($$v) {_vm.isScheduled=$$v},expression:"isScheduled"}})],1)])],1)]}}])},[(_vm.schedule == null && _vm.isScheduled)?_c('span',[_vm._v(" This flow is trying to schedule runs but has no schedules! Visit this flow's "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Settings > Schedules")]),_vm._v(" to set a new schedule. ")]):(_vm.archived)?_c('span',[_vm._v(" Archived flows cannot be scheduled. ")]):_c('span',[_vm._v(" Turn "+_vm._s(_vm.isScheduled ? 'off' : 'on')+" this flow's schedule ")])]),_c('DeleteButton',{attrs:{"flow":_vm.flow,"flow-group":_vm.flowGroup,"type":"flow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }